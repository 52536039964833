import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ScrollToTop from "../components/ScrollToTop";
import logo from '../assets/images/ekc-logo-white.webp';
import { navItems } from "../common/constants";
import { useOutlet } from "react-router-dom";

const MainLayout = () => {
    const outlet = useOutlet();

    return (
        <>
            <NavBar logo={logo} navItems={navItems} />
            {outlet}
            <ScrollToTop />
            <Footer logo={logo} copyrightText='Copyright @ 2025 EnterpriseKC' />
        </>
    );
};

export default MainLayout;