import { BiSolidBusiness } from "react-icons/bi";
import { BsBuildingsFill } from "react-icons/bs";
import { FaBuilding, FaBuildingColumns } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import { IoIosPersonAdd } from "react-icons/io";
import { ColumnConfig, GlobeCluster, GlobeGroup, GlobePoint, GlobeType, IndustryURLs, NavItem, Resource, Stat } from "./types";

import Pillar1 from '../assets/images/pillar1.webp';
import Pillar2 from '../assets/svgs/NewPillar2.svg';
import Pillar3 from '../assets/images/pillar3.webp';
import Pillar4 from '../assets/images/pillar4.webp';
import Pillar5 from '../assets/images/pillar5.webp';
import Pillar6 from '../assets/images/pillar6.webp';
import Pillar7 from '../assets/images/pillar7.webp';
import Pillar8 from '../assets/images/pillar8.webp';

import TabImage01 from "../assets/images/vision_formula.png";
import TabImage02 from "../assets/images/mission_formula.png";
import TabImage03 from "../assets/images/strategy_formula.png";
import TabImage04 from "../assets/images/structure_formula.png";
import TabImage05 from "../assets/images/people_formula.png";
import TabImage06 from "../assets/images/process_formula.png";
import TabImage07 from "../assets/images/tools_formula.png";
import TabImage08 from "../assets/images/results_formula.png";


export const statsAdvancedManufacturing: Stat[] = [
    { label: "Total Companies", value: 1737, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 252, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 143, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 83, image: BiSolidBusiness },
    { label: 'Businesses', value: 26, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 97.7, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 96.8, image: IoIosPersonAdd },
];

export const statsArchitectureEngineering: Stat[] = [
    { label: "Total Companies", value: 362, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 277, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 83, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 61, image: BiSolidBusiness },
    { label: 'Businesses', value: 133, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 98.5, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 88.8, image: IoIosPersonAdd },
];

export const statsCybersecurity: Stat[] = [
    { label: "Total Companies", value: 72, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 61, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 29, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 11, image: BiSolidBusiness },
    { label: 'Businesses', value: 21, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 98.4, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 68.2, image: IoIosPersonAdd },
];

export const statsDigitalHealth: Stat[] = [
    { label: "Total Companies", value: 3557, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 250, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 16, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 37, image: BiSolidBusiness },
    { label: 'Businesses', value: 197, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 29.6, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 31.6, image: IoIosPersonAdd },
];

export const statsPlantAnimalSciences: Stat[] = [
    { label: "Total Companies", value: 933, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 264, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 117, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 83, image: BiSolidBusiness },
    { label: 'Businesses', value: 64, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 98.9, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 87.0, image: IoIosPersonAdd },
];

export const statsHumanLifeSciences: Stat[] = [
    { label: "Total Companies", value: 147, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 132, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 35, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 33, image: BiSolidBusiness },
    { label: 'Businesses', value: 64, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 94.3, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 97.2, image: IoIosPersonAdd },
];

export const statsFinancialServices: Stat[] = [
    { label: "Total Companies", value: 4349, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 354, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 63, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 69, image: BiSolidBusiness },
    { label: 'Businesses', value: 222, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 83.9, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 72.5, image: IoIosPersonAdd },
];

export const statsTechnology: Stat[] = [
    { label: "Total Companies", value: 1198, image: FaBuildingColumns },
    { label: 'Verified Companies', value: 251, image: FaBuildingColumns },
    { label: 'Primary Enterprises', value: 81, image: FaBuilding },
    { label: 'Secondary Enterprises', value: 75, image: BiSolidBusiness },
    { label: 'Businesses', value: 95, image: BsBuildingsFill },
    { label: '% Revenue by Enterprises', value: 99.1, image: FaMoneyBillWave },
    { label: '% Employment by Enterprises', value: 95.7, image: IoIosPersonAdd },
];

export const navItems: NavItem[] = [
    { type: 'link', label: "Home", to: "/" },
    { type: 'link', label: "Our Approach", to: "/our-approach" },
    {
        type: 'dropdown',
        label: "Clusters",
        leftColumn: {
            title: 'Clusters',
            items: [
                'Advanced Manufacturing',
                "Architecture-Engineering",
                'Cybersecurity',
                "Digital Health",
                "Financial Services-Insurance",
                "Human Life Sciences",
                "Plant & Animal Sciences",
                "Technology",
            ],
        },
        clickableLabel: false
    },
    {
        type: 'dropdown',
        label: "Projects",
        leftColumn: {
            title: 'Current Initiatives',
            items: [
                "Datasphere",
                'Heartland Cyber Range',
                "Secure Space Campus",
                'Talent Platform',
            ],
        },
        clickableLabel: false
    },
    { type: 'link', label: "Asset Map", to: "/asset-map" },


    { type: 'link', label: "Resources", to: "/resources" },
];

export const globePointColumns: ColumnConfig<GlobePoint>[] = [
    { key: 'pointId', label: 'ID' },
    { key: 'name', label: 'Name' },
    { key: "description", label: "Description" },
    { key: "groupId", label: "Group" },
    { key: "types", label: "Types" },
    { key: "lng", label: "Longitude" },
    { key: "lat", label: "Latitude" },
    { key: "state", label: "State" },
    { key: "link", label: "Link" },
];

export const globeGroupColumns: ColumnConfig<GlobeGroup>[] = [
    { key: 'groupId', label: 'ID' },
    { key: 'groupName', label: 'Name' },
    { key: "arcColor", label: "Arc Color" },
    { key: "pointColor", label: "Point Color" },
    { key: "clusterId", label: "Cluster ID" },
];

export const globeTypeColumns: ColumnConfig<GlobeType>[] = [
    { key: 'typeId', label: 'ID' },
    { key: 'typeName', label: 'Name' },
];

export const globeClusterColumns: ColumnConfig<GlobeCluster>[] = [
    { key: 'clusterId', label: 'ID' },
    { key: 'name', label: 'Name' },
    { key: "description", label: "Description" },
];

export const resourceColumns: ColumnConfig<Resource>[] = [
    { key: 'id', label: 'ID' },
    { key: 'title', label: 'Title' },
    { key: "subtitle", label: "Subtitle" },
    { key: "imageLink", label: "Image" },
    { key: "pdfLink", label: "PDF Link" },
    { key: "tag", label: "Tag" }
];

export const industryURLs: IndustryURLs = {
    kansas: {
        "default": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries",
        "advanced-manufacturing": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,13,19,41,49,51",
        "cybersecurity": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,15,17",
        "architecture-and-engineering": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,13,17,19,27",
        "financial-services": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,13,15,19,41,43",
        "human-life-sciences": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,15,19,29,41,49",
        "plant-and-animal-sciences": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,19,29,45,41,49,15",
        "technology": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=11,15,49,17,27",
        "digital-health": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries?soc=21,29,31,43,49,51,15,19,29",
    },
    missouri: {
        "default": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO",
        "advanced-manufacturing": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,13,19,41,49,51",
        "cybersecurity": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,15,17",
        "architecture-and-engineering": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,13,17,19,27",
        "financial-services": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,13,15,19,41,43",
        "human-life-sciences": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,15,19,29,41,49",
        "plant-and-animal-sciences": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,19,29,45,41,49,15",
        "technology": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=11,15,49,17,27",
        "digital-health": "https://enterprise-kc.eimpactv3.com/dashboards/213/enterprise-kc/Enterprise-KC-Target-Industries---MO?soc=21,29,31,43,49,51,15,19,29",
    }
}

export const pillars = [
    {
        id: 'pillar1',
        title: 'Pillar 1',
        tagline: 'Opportunity Analysis, Vision Description & Branding (Market Opportunity)',
        description: 'Build and promote a compelling story, ensuring the rest of the world understands the many assets and opportunities our region offers, making the region a magnet for talent and enterprises.',
        image: Pillar1,
    },
    {
        id: 'pillar2',
        title: 'Pillar 2',
        tagline: 'Workforce Demand (Enterprise Demand & Specification)',
        description: 'Create the most agile, educated, diverse, and experientially trained workforce in the country for high opportunity, high demand jobs.',
        image: Pillar2,
    },
    {
        id: 'pillar3',
        title: 'Pillar 3',
        tagline: 'Workforce Supply Chain (Talent Pipeline)',
        description: 'Retool the talent supply chain to identify and advance high demand, high opportunity aptitudes earlier and produce more diverse and workforce ready graduates across the region.',
        image: Pillar3,
    },
    {
        id: 'pillar4',
        title: 'Pillar 4',
        tagline: 'Innovation and Commercialization Supply Chain (Innovation Pipeline)',
        description: 'Substantially increase the volume of high opportunity research conducted among the universities and enterprises in the region and ensure that the research is commercialized in the region.',
        image: Pillar4,
    },
    {
        id: 'pillar5',
        title: 'Pillar 5',
        tagline: 'Enterprise Growth (Start-up, Gazelle & Enterprise Development)',
        description: 'Accelerate economic development through industry-led initiatives that foster enterprise growth, new startups, and the targeted recruitment of new enterprises to the region.',
        image: Pillar5,
    },
    {
        id: 'pillar6',
        title: 'Pillar 6',
        tagline: 'Network Development & Alignment (Clarity, Alignment, & Focus Across All Eco-Devo Groups)',
        description: 'Align resources to support an economic infrastructure that ensures graduates remain here, enterprises grow here, the region becomes a magnet for enterprises and talent to relocate here, and that all citizens across the region prosper.',
        image: Pillar6,
    },
    {
        id: 'pillar7',
        title: 'Pillar 7',
        tagline: 'Infrastructure & Finance (Investing For Strategic Growth)',
        description: 'Develop cluster-specific state-of-the-art infrastructure and attract outside capital sources to enhance and advance innovation tied to enterprise development and workforce education.',
        image: Pillar7,
    },
    {
        id: 'pillar8',
        title: 'Pillar 8',
        tagline: 'Big Data & Analytics (Single Source of Truth)',
        description: 'Map all enterprises, business, government, academic, and innovation assets into a Single Source of Truth (EnterpriseKC Datasphere) to support strategy development and cluster-level decision support and measure and report results against objectives and return on investment.',
        image: Pillar8,
    },
];

export const visionToValueTabs = [
    {
        title: 'Vision',
        img: TabImage01,
        tag: '#1',
        excerpt: "A vivid description of a future state; it is both a purpose and a destination. It’s connecting the dots; finding those key patterns that will create fundamental change and move people to a new future state. Vision is the ultimate alignment technique – it creates a purpose or a “why” for the journey.",
        link: '#0'
    },
    {
        title: 'Mission',
        img: TabImage02,
        tag: "#2",
        excerpt: "The actionable, achievable, AND repeatable execution of the vision. Mission is the clear direction required to create the future state described by the vision.",
        link: '#0'
    },
    {
        title: 'Strategy',
        img: TabImage03,
        tag: "#3",
        excerpt: "A statement of the desired pathway to achieve the vision, mission, goals, and objectives and the tactics used to get there. Successful strategies create competitive advantage.",
        link: '#0'

    },
    {
        title: 'Structure',
        img: TabImage04,
        tag: "#4",
        excerpt: "Structure consists of optimizing the organizational relationships of people and functions to achieve results.",
        link: '#0'
    },
    {
        title: 'People',
        img: TabImage05,
        tag: "#5",
        excerpt: "The most precious asset of any high-performance organization. To win in the marketplace requires first winning the people game, and to win the people game requires a maniacal focus on the Associate experience.",
        link: '#0'
    },
    {
        title: 'Process',
        img: TabImage06,
        tag: "#6",
        excerpt: "Repeatable methods used to accomplish work in a predictable manner. Growth will obsolete an organization’s processes, so they must be continuously evaluated.",
        link: '#0'
    },
    {
        title: 'Tools',
        img: TabImage07,
        tag: "#7",
        excerpt: "Constructed devices and applications used to aid in achieving results.",
        link: '#0'
    },
    {
        title: 'Results',
        img: TabImage08,
        tag: "#8",
        excerpt: "An outcome that creates value. Results MUST be measured. Results are measured three ways: Attainment – the achievement of a discrete measurable goal: it’s a yes or a no. Trend – a quantitative review of attainment versus plan over a defined period of time. Variance – the amount of spread in what was expected versus what was planned.",
        link: '#0'
    }
]

export const clusterStatId = {
    ADVANCED_MANUFACTURING: 32,
    ARCHITECTURE_ENGINEERING: 37,
    CYBERSECURITY: 36,
    DIGITAL_HEALTH: 18,
    FINANCIAL_SERVICES: 25,
    HUMAN_LIFE_SCIENCES: 19,
    PLANT_ANIMAL_SCIENCES: 31,
    TECHNOLOGY: 22,
}
